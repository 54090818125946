import { useCallback, useEffect, useMemo, useState } from 'react'
import { DHP_SERVICES, FALLBACK_PRINTER_COUNTRY } from '@/store/Constants'
import { Logger } from '@/utils/Logger'
import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'

function getLedmCountryFromXml(xml) {
  return xml.getElementsByTagName('prdcfgdyn:CountryAndRegionName')[0]
    .textContent
}

function getLedmProductNumberFromXml(xml) {
  return xml.getElementsByTagName('dd:ProductNumber')[0].textContent
}

export function usePrinterSystemConfig() {
  const { sendRequest, isCdm, readyToSendRequest } = useDeviceHttpProxy()
  const [printerCountry, setPrinterCountry] = useState(undefined)
  const [fetchedDeviceCountry, setFetchedDeviceCountry] = useState(false)

  const getPrinterCountry = useCallback(async () => {
    try {
      const { body } = await sendRequest(DHP_SERVICES.GET_SYSTEM_CONFIG)

      const country =
        (isCdm ? body.data.countryRegion : getLedmCountryFromXml(body.data)) ||
        FALLBACK_PRINTER_COUNTRY

      setPrinterCountry(country)
    } catch (e) {
      Logger.error('Error getting printer country', e)
      setPrinterCountry(FALLBACK_PRINTER_COUNTRY)
    }
  }, [sendRequest, isCdm])

  const getProductNumber = useCallback(async () => {
    const {
      body: { data }
    } = await sendRequest(DHP_SERVICES.GET_SYSTEM_CONFIG)

    return isCdm ? data.productNumber : getLedmProductNumberFromXml(data)
  }, [sendRequest, isCdm])

  useEffect(() => {
    if (readyToSendRequest && !fetchedDeviceCountry) {
      setFetchedDeviceCountry(true)
      getPrinterCountry()
    }
  }, [
    getPrinterCountry,
    setFetchedDeviceCountry,
    fetchedDeviceCountry,
    readyToSendRequest,
    getProductNumber
  ])

  return useMemo(() => ({ printerCountry, getProductNumber }), [
    printerCountry,
    getProductNumber
  ])
}
