import React, { useContext } from 'react'
import { ConfigContext, UserContext } from '@/store'
import classes from './FinishSetupPage.module.scss'
import TextHeader from '@/components/UI/TextHeader'
import SetupOption from '@/components/FinishSetup/SetupOption'
import NextStageButton from '@/components/UI/NextStageButton'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES } from '@/store/Constants'

const FinishSetupEcpPage = () => {
  const { getSessionId } = useContext(UserContext)
  const configCtx = useContext(ConfigContext)

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.finish_setup_ecp
  })

  const exitClickHandler = () => {
    configCtx.nextStage({
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  if (!pageData) return <></>

  return (
    <div data-testid="finish-setup-page-for-ecp">
      <TextHeader>{pageData.header}</TextHeader>
      <div className={classes.setupContainer}>
        {pageData.setup_list.map((setup_item, index) => (
          <SetupOption
            key={index}
            icon={setup_item.veneer_icon?.icon}
            header={setup_item.header}
            description={setup_item.description}
          />
        ))}
        <div className={`${classes.button} ${classes.ecpButtonContainer}`}>
          <NextStageButton
            onClick={exitClickHandler}
            dataTestId="exit-setup"
            copy={pageData.primary_button_text}
          />
        </div>
      </div>
    </div>
  )
}

export default FinishSetupEcpPage
