import React, { useContext, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'
import { useNextStage } from '@/hooks/useNextStage'
import { PrinterContext } from '@/store'
import { CONTENT_STACK_TYPES } from '@/store/Constants'
import GenericModal, {
  GENERIC_MODAL_TYPES
} from '@/components/UI/Modals/GenericModal'
import { IconCheckmark } from '@veneer/core/dist/scripts/icons'

import classes from './LandingPage.module.scss'

export function LandingPage() {
  const [openModal, setOpenModal] = useState(false)

  const { printerFunctionType } = useContext(PrinterContext)
  const { dispatchSuccessStage } = useNextStage()
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.landing_page_app,
    additional_params: {
      printer_function_type: printerFunctionType
    }
  })

  const handleContinue = () => {
    // TODO: Analytics
    dispatchSuccessStage()
  }

  const handleExit = () => {
    // TODO: Analytics
    setOpenModal(true)
  }

  if (!pageData) {
    return null
  }

  return (
    <>
      <div
        data-testid="landing-page-smallville"
        className={classes.landingPageSmallville}
      >
        <header>
          <h5>{pageData.header}</h5>
          <p>{pageData.description}</p>
        </header>
        <div className={classes.content}>
          <section className={classes.features}>
            <h6>{pageData.features.title}</h6>
            <ul>
              {pageData.features.list.map((feature) => (
                <li key={feature}>
                  <IconCheckmark size={24} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </section>
          <section className={classes.printerImage}>
            <img
              alt={pageData.printer_function_image.title}
              src={pageData.printer_function_image.url}
            />
          </section>
        </div>
        <footer>
          <Button appearance="secondary" onClick={handleExit}>
            {pageData.buttons.exit}
          </Button>
          <Button appearance="primary" onClick={handleContinue}>
            {pageData.buttons.continue}
          </Button>
        </footer>
      </div>
      <GenericModal
        type={GENERIC_MODAL_TYPES.EXIT_HP_CLOUD_CONNECTION}
        visible={openModal}
        setVisibility={setOpenModal}
      />
    </>
  )
}
