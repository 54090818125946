import { useState, useRef, useEffect, useContext } from 'react'
import { TenancyRegistryClient } from '@/clients/TenancyRegistryClient'
import { ConfigContext } from '@/store'
import { ACCOUNT_TYPE_COMPANY } from '@/store/Constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Logger } from '@/utils/Logger'

const useTenancyRegistry = () => {
  const tenancyClient = useRef(null)
  const { authProvider, stack } = useContext(ConfigContext)
  const [isFetching, setIsFetching] = useState(false)
  const [availableOrganizations, setAvailableOrganizations] = useState(null)
  const {
    onbpReleaseTenantlesstokensupport: isTenantlessTokenSupported
  } = useFlags()

  const getOrganizations = (type = ACCOUNT_TYPE_COMPANY) => {
    setIsFetching(true)
    if (!tenancyClient.current) return
    let nodes = []
    return tenancyClient.current
      .getDomains()
      .then(async (response) => {
        for (const node of response.data.content) {
          await tenancyClient.current.getNode(node).then((nodeResponse) => {
            if (nodeResponse.data.type === type) {
              nodes.push(nodeResponse.data)
            }
          })
        }
        setAvailableOrganizations(nodes)
      })
      .catch((e) => {
        Logger.error('getDomains error:', e)
        setAvailableOrganizations([])
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    tenancyClient.current = new TenancyRegistryClient(
      stack,
      isTenantlessTokenSupported
        ? authProvider.createOrglessAuthProvider()
        : authProvider
    )
    return () => {
      tenancyClient.current = null
    }
  }, [authProvider, stack, isTenantlessTokenSupported])

  return {
    isFetching,
    availableOrganizations,
    getOrganizations
  }
}

export default useTenancyRegistry
