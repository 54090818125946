import React from 'react'

import classes from './TextHeader.module.scss'
import clsx from 'clsx'

export const TextSubHeader = (props) => {
  return (
    <div
      {...props}
      className={clsx(classes.subHeader, props.className)}
      data-testid="text-subheader"
    />
  )
}

const TextHeader = ({ children }) => {
  return (
    <div className={classes.header} data-testid="text-header">
      {children}
    </div>
  )
}

export default TextHeader
