import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'
import { useDeviceSetup } from '@/hooks/DeviceHttpProxy/useDeviceSetup'
import { useCallback, useMemo } from 'react'
import {
  DHP_SERVICES,
  FW_UPDATE_NOTIFY,
  ACTION_AFU_PROMPT
} from '@/store/Constants'
import { Logger } from '@/utils/Logger'
import { transposeBoolInEnableDisable } from '@/utils/DeviceHttpProxy'

export function useFirmwareUpdateConfig() {
  const { sendRequest, isCdm } = useDeviceHttpProxy()
  const { updateActionInDeviceSetup } = useDeviceSetup()

  const writeFWUChoiceOnPrinter = useCallback(
    async (choice) => {
      const userConfirmationEnabled = choice === FW_UPDATE_NOTIFY
      const cdmBody = {
        autoUpdateEnabled: 'true',
        userConfirmationEnabled: userConfirmationEnabled.toString()
      }
      const ledmBodyParser = (template) =>
        template.replace(
          '$AutomaticUpdate',
          transposeBoolInEnableDisable(!userConfirmationEnabled)
        )

      try {
        await sendRequest(
          DHP_SERVICES.SET_FW_UPDATE_CHOICE,
          cdmBody,
          ledmBodyParser
        )
        if (isCdm) {
          const bodyToUpdate = {
            [ACTION_AFU_PROMPT]: {
              userResponse: 'completed'
            }
          }
          await updateActionInDeviceSetup(ACTION_AFU_PROMPT, bodyToUpdate)
        }
      } catch (error) {
        // We're suppressing the errors so far because there isn't design to present to the user or a logic accorded with PO about handling that
        Logger.error('Error trying to write fw update choice on printer', error)
      }
    },
    [isCdm, sendRequest, updateActionInDeviceSetup]
  )
  return useMemo(() => ({ writeFWUChoiceOnPrinter }), [writeFWUChoiceOnPrinter])
}
