import { DeviceAuthGrantApiClient } from '@/clients/DeviceAuthGrantApiClient'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import GhostPrinter from '@/assets/GhostPrinter.svg'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { OFFER_HINT_DUAL_SKU, OFFER_HINT_SINGLE_SKU } from '@/store/Constants'
import { CUSTOM_ERRORS } from '@/store/ErrorContext'
import { PrinterDetailsClient } from '@/clients/PrinterDetailsClient'
import { Logger } from '@/utils/Logger'

const useDeviceAuthGrantApi = () => {
  const PrinterCtx = useContext(PrinterContext)
  const { getSessionId } = useContext(UserContext)
  const { authProvider, stack, sessionContext } = useContext(ConfigContext)
  const [errorCode, setErrorCode] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isValid, setIsValid] = useState(null)
  const [dagOffer, setDagOffer] = useState(null)
  const client = useRef(null)
  const printerDetailsClient = useRef(null)
  const expectedBizModel = sessionContext.onboardingContext.bizModelHint
  const expectedOffer = sessionContext.onboardingContext.offerHint
  const isValidating = useRef(false)

  const performClientRequest = (
    request,
    handleResponse,
    handleError,
    holdDevice,
    setFetching = true
  ) => {
    setIsValid(null)
    setIsFetching(true)
    setErrorCode(null)
    Logger.log('performing clientRequest')
    return request()
      .then(({ status, data }) => {
        handleResponse(status, data)
        if (setFetching) {
          setIsFetching(false)
        }
      })
      .catch((error) => {
        Logger.error(
          'performClientRequest (useDeviceAuthGrantApi) error:',
          error
        )
        setIsValid(false)
        setIsFetching(false)
        if (handleError && error?.response) {
          const { status, data } = error.response
          return handleError(status, data)
        }
        if (error?.response?.data?.error_code) {
          setErrorCode(error.response.data.error_code)
          return
        }
        holdDevice
          ? setErrorCode(`OP_XXX_UTPR00002_VP`)
          : setErrorCode(`OP_XXX_UTPR00002_SP`)
      })
  }

  const handleDeviceResponse = (status, data) => {
    if (data.deviceCapabilities) {
      PrinterCtx.setDeviceCapabilities(data.deviceCapabilities)
    }
    PrinterCtx.setIsMono(data.colorSupported !== 'color')
    PrinterCtx.setDeviceSegment(data.deviceSegment)
    PrinterCtx.setConnectivityFamily(data.connectivityFamily)
    PrinterCtx.setOid(data.oid)
    try {
      let highestResolution = 0
      let highestResolutionImage = GhostPrinter
      for (let image of data.images) {
        const resolutionComponent = image.split('/').pop()
        const size = resolutionComponent.split('.')[0].split('x')
        const resolution = parseInt(size[0]) * parseInt(size[1])
        if (resolution > highestResolution) {
          highestResolution = resolution
          highestResolutionImage = image
        }
      }
      PrinterCtx.setPrinterImage(highestResolutionImage)
    } catch (err) {
      // In the event of a failure default back to the Ghost Image in case
      // the image has been overwritten
      PrinterCtx.setPrinterImage(GhostPrinter)
    } finally {
      setIsValid(true)
      isValidating.current = false
    }
  }

  const fetchPrinterDetails = useCallback(
    (productNumber, holdDevice = true) =>
      performClientRequest(
        printerDetailsClient.current.getPrinterDetails.bind(
          printerDetailsClient.current,
          productNumber
        ),
        handleDeviceResponse,
        handleDeviceResponse,
        holdDevice
      ),
    [performClientRequest, handleDeviceResponse]
  )

  const factoryHandleValidateResponse = (holdDevice, onSuccess) => (
    status,
    data
  ) => {
    try {
      const {
        device_model,
        product_number,
        user_code,
        interval,
        biz_model,
        offer,
        device_uuid,
        serial_number
      } = data
      PrinterCtx.setUuid(device_uuid)
      PrinterCtx.setSerialNumber(serial_number)
      if (!holdDevice) {
        const { device_postcard, fingerprint } = data
        PrinterCtx.setClaimPostcard(device_postcard)
        if (fingerprint) {
          PrinterCtx.setFingerprint(fingerprint)
        }
        setTimeout(() => {
          onSuccess(device_uuid)
        }, PrinterCtx.pollingInterval * 1000)
        isValidating.current = false
        return
      }
      PrinterCtx.setBizModel(biz_model)
      setDagOffer(offer)
      PrinterCtx.setProductNumber(product_number)
      PrinterCtx.setModelName(device_model)
      PrinterCtx.setPairingCode(user_code)
      PrinterCtx.setPollingInterval(interval)
      PrinterCtx.setOffer(offer)
      // Type coercion is good here: '0' == 0, where as without: 0 !== '0'
      if (
        biz_model.toLowerCase() !== expectedBizModel.toLowerCase() ||
        (!offer && expectedOffer === OFFER_HINT_SINGLE_SKU) ||
        (offer && expectedOffer === OFFER_HINT_DUAL_SKU)
      ) {
        setErrorCode(`OP_200_${CUSTOM_ERRORS.biz_model_mismatch}_VP`)
        setIsValid(false)
        return
      }
      fetchPrinterDetails(product_number, holdDevice)
    } catch (err) {
      Logger.error(
        'factoryHandleValidateResponse (useDeviceAuthGrantApi) error:',
        err
      )
      // Invalid response data in /verify payload
      holdDevice
        ? setErrorCode(`OP_XXX_UTPR00001_VP`)
        : setErrorCode(`OP_XXX_UTPR00001_SP`)
      setIsValid(false)
      isValidating.current = false
    }
  }

  const factoryHandleErrorValidate = (holdDevice) => (status, data) => {
    if (!holdDevice) {
      data?.error_code
        ? setErrorCode(`DA_${status}_${data.error_code}_SP`)
        : setErrorCode(`DA_${status}_OPVP00002_SP`)
      setIsValid(false)
    } else {
      data?.error_code
        ? setErrorCode(`DA_${status}_${data.error_code}_VP`)
        : setErrorCode(`DA_${status}_OPVP00003_VP`)
    }
    isValidating.current = false
  }

  const validate = useCallback(
    (code, holdDevice = true, onSuccess = EMPTY_FUNCTION) => {
      if (isValidating?.current) return
      isValidating.current = true
      return performClientRequest(
        client.current.verifyPairing.bind(client.current, code, holdDevice, {
          'X-Correlation-ID': getSessionId()
        }),
        factoryHandleValidateResponse(holdDevice, onSuccess),
        factoryHandleErrorValidate(holdDevice),
        false
      )
    },
    [PrinterCtx, getSessionId, expectedBizModel, expectedOffer]
  )

  const confirm = useCallback(
    (uuid = PrinterCtx.uuid) => {
      return performClientRequest(
        client.current.confirmPairing.bind(client.current, uuid, {
          'X-Correlation-ID': getSessionId()
        }),
        (status, data) => {
          setIsValid(data?.status === 'access_granted')
        },
        (status, data) => {
          if (data?.error === 'authorization_pending') {
            return setTimeout(
              confirm.bind(null, uuid),
              PrinterCtx.pollingInterval * 1000
            )
          }
          // Unable to negotiate token with DAG
          setErrorCode(`DA_${status}_OPVP00001_CP`)
          setIsValid(false)
        }
      )
    },
    [PrinterCtx.uuid, PrinterCtx.pollingInterval, getSessionId]
  )

  const clear = useCallback(() => {
    setIsValid(null)
    setIsFetching(false)
    setErrorCode(null)
    setDagOffer(null)
    isValidating.current = false
  }, [setIsValid, setIsFetching, setErrorCode, setDagOffer])

  useEffect(() => {
    client.current = new DeviceAuthGrantApiClient(stack, authProvider)
    printerDetailsClient.current = new PrinterDetailsClient(stack)
    return () => {
      client.current = null
      printerDetailsClient.current = null
    }
  }, [authProvider, stack])

  return {
    errorCode,
    isFetching,
    isValid,
    offer: dagOffer,
    validate,
    clear,
    confirm,
    fetchPrinterDetails
  }
}

export default useDeviceAuthGrantApi
