import React, { Suspense } from 'react'
import classes from './SetupOption.module.scss'
import { CmsVeneerIcon } from '@/styles/LazyVeneer'

const SetupOption = ({ icon, header, description }) => {
  return (
    <div data-testid="setup-option" className={classes.setupSection}>
      <Suspense fallback={<></>}>
        <CmsVeneerIcon icon={icon} size={50} />
      </Suspense>
      <section>
        <h2>{header}</h2>
        <p>{description}</p>
      </section>
    </div>
  )
}

export default SetupOption
