// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3kiuVNSdtkNreZB8ZwoI5L{margin-top:40px}._3kiuVNSdtkNreZB8ZwoI5L ._1LDQcsNMeljZRT-Q3q8QwF{height:102px}._3kiuVNSdtkNreZB8ZwoI5L .QDjyXVvV9MUBNCtYPKOsa{max-width:360px}._3kiuVNSdtkNreZB8ZwoI5L .QDjyXVvV9MUBNCtYPKOsa:last-of-type{margin-bottom:72px}._1odeELPHe5vXeV0xh-Ny9Q{display:flex;justify-content:flex-end}._1odeELPHe5vXeV0xh-Ny9Q button:first-of-type{min-width:80px;margin-right:12px}@media screen and (max-width: 1279px){._3kiuVNSdtkNreZB8ZwoI5L{margin-top:16px}}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/Activation/NamePrinter.module.scss"],"names":[],"mappings":"AAAA,yBAAQ,eAAe,CAAC,kDAAuB,YAAY,CAAC,gDAAiB,eAAe,CAAC,6DAA8B,kBAAkB,CAAC,yBAAS,YAAY,CAAC,wBAAwB,CAAC,8CAA8B,cAAc,CAAC,iBAAiB,CAAC,sCAAsC,yBAAQ,eAAe,CAAC","sourcesContent":[".inputs{margin-top:40px}.inputs .textContainer{height:102px}.inputs .textBox{max-width:360px}.inputs .textBox:last-of-type{margin-bottom:72px}.buttons{display:flex;justify-content:flex-end}.buttons button:first-of-type{min-width:80px;margin-right:12px}@media screen and (max-width: 1279px){.inputs{margin-top:16px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "_3kiuVNSdtkNreZB8ZwoI5L",
	"textContainer": "_1LDQcsNMeljZRT-Q3q8QwF",
	"textBox": "QDjyXVvV9MUBNCtYPKOsa",
	"buttons": "_1odeELPHe5vXeV0xh-Ny9Q"
};
export default ___CSS_LOADER_EXPORT___;
