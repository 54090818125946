import React, { useContext, useState, useEffect, useMemo } from 'react'
import { ConfigContext, UserContext } from '@/store'
import TextHeader from '@/components/UI/TextHeader'
import classes from './FirmwareUpdateChoice.module.scss'
import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  FW_UPDATE_AUTO,
  FW_UPDATE_NOTIFY,
  CONTENT_STACK_TYPES,
  LEDM_ITALY,
  CDM_ITALY,
  ITALY_COUNTRY_CODE,
  REST_OF_WORLD,
  SPLUNK_RUM_FIELDS,
  SPLUNK_RUM_RUN_TIME_WORKFLOWS,
  SPLUNK_RUM_CUSTOM_EVENTS
} from '@/store/Constants'
const { BUTTONS, SCREENS, LINKS, MODES } = ANALYTICS
import useAnalytics from '@/hooks/useAnalytics'
import NextStageButton from '@/components/UI/NextStageButton'
import RadioButtons from '@veneer/core/dist/scripts/radio_buttons'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import useContentStack from '@/hooks/useContentStack'
import clsx from 'clsx'
import { usePrinterSystemConfig } from '@/hooks/DeviceHttpProxy/usePrinterSystemConfig'
import { useFirmwareUpdateConfig } from '@/hooks/DeviceHttpProxy/useFirmwareUpdateConfig'
import { useSplunkRum } from '@/hooks/useSplunkRum'

const FirmwareUpdateChoicePage = () => {
  const { isMobile, isBinaryApp, nextStage, sessionContext } = useContext(
    ConfigContext
  )
  const { getSessionId } = useContext(UserContext)
  const [isEligible, setIsEligible] = useState(false)
  const [selectedRadioButton, setSelectedRadioButton] = useState(null)
  const {
    pageData,
    dataRetrieved,
    currentlyFetching,
    startQuery
  } = useContentStack({ queryOnInit: false })
  const { printerCountry } = usePrinterSystemConfig()

  const fwUpdateAnalytics = useAnalytics(SCREENS.FIRMWARE_UPDATE)
  const fwUpdateChoiceAnalytics = useAnalytics(SCREENS.FIRMWARE_UPDATE_CHOICE)
  const { writeFWUChoiceOnPrinter } = useFirmwareUpdateConfig()
  const { publishSpanEvent } = useSplunkRum(
    SPLUNK_RUM_CUSTOM_EVENTS.FW_UPDATE_CHOICE
  )

  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useMemo(
    () => (isBinaryApp ? fwUpdateChoiceAnalytics : fwUpdateAnalytics),
    [isBinaryApp, fwUpdateChoiceAnalytics, fwUpdateAnalytics]
  )

  useEffect(() => {
    if (currentlyFetching || dataRetrieved) return
    if (!isBinaryApp) {
      startQuery({
        content_type: CONTENT_STACK_TYPES.firmware_update_choice
      })
    } else if (printerCountry) {
      const queryCountry =
        [LEDM_ITALY, CDM_ITALY].indexOf(printerCountry.toLowerCase()) > -1
          ? ITALY_COUNTRY_CODE
          : REST_OF_WORLD
      startQuery({
        content_type: CONTENT_STACK_TYPES.firmware_update_choice,
        additional_params: {
          printer_country: queryCountry
        }
      })
    }
  }, [
    currentlyFetching,
    dataRetrieved,
    isBinaryApp,
    printerCountry,
    startQuery
  ])

  const fwContainerClasses = clsx(classes.firmwareUpdateChoice, {
    [classes.isBinaryAppMobile]: isBinaryApp && isMobile
  })

  const learnMoreClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(LINKS.LEARN_MORE)
    }
  }

  const radioButtonHandler = (value) => {
    if (value === FW_UPDATE_AUTO) {
      fireButtonClick(BUTTONS.APPLY, { detail: 'AutoUpdate' })
    } else if (value === FW_UPDATE_NOTIFY) {
      fireButtonClick(BUTTONS.APPLY, { detail: 'Notify' })
    }
    setSelectedRadioButton(value)
  }

  useEffect(() => {
    try {
      const bizModel = sessionContext?.user?.selectedBizModel?.toUpperCase?.()
      if (bizModel === BIZ_MODEL_HP_PLUS) {
        nextStage({
          result: 'ineligible',
          xCorrelationId: getSessionId()
        })
        return
      }
      setIsEligible(true)
    } catch (_) {
      setIsEligible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pageData) {
      if (isBinaryApp)
        fireScreenDisplayed({
          mode:
            [LEDM_ITALY, CDM_ITALY].indexOf(printerCountry?.toLowerCase()) > -1
              ? MODES.SCREEN.ITALY
              : MODES.SCREEN.REST_OF_WORLD
        })
      else fireScreenDisplayed()
    }
  }, [pageData])

  const selectFirmwareUpdate = async () => {
    fireButtonClick(BUTTONS.APPLY)
    if (isBinaryApp) {
      publishSpanEvent({
        [SPLUNK_RUM_FIELDS.WORKFLOW]:
          selectedRadioButton === FW_UPDATE_AUTO
            ? SPLUNK_RUM_RUN_TIME_WORKFLOWS.FW_UPDATE_AUTO
            : SPLUNK_RUM_RUN_TIME_WORKFLOWS.FW_UPDATE_NOTIFY,
        [SPLUNK_RUM_FIELDS.PRINTER_COUNTRY]:
          [LEDM_ITALY, CDM_ITALY].indexOf(printerCountry?.toLowerCase?.()) > -1
            ? MODES.SCREEN.ITALY
            : MODES.SCREEN.REST_OF_WORLD
      })
      await writeFWUChoiceOnPrinter(selectedRadioButton)
    }
    nextStage({
      output: {
        firmwareUpdateConfig: {
          updateType: selectedRadioButton
        }
      },
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  if ((!isBinaryApp && !isEligible) || !pageData) return <></>

  return (
    <>
      <div className={fwContainerClasses} data-testid="firmware-update-choice">
        <TextHeader>{pageData.header}</TextHeader>
        <p
          onClick={learnMoreClickHandler}
          dangerouslySetInnerHTML={{
            __html: pageData.description
          }}
        />
        <div className={classes.radioSection}>
          <RadioButtons
            name="radiobutton_default"
            onChange={(_e, value) => radioButtonHandler(value)}
          >
            <RadioButton
              checked={selectedRadioButton === FW_UPDATE_AUTO}
              className={classes.radioButton}
              key="auto_update_button"
              value={FW_UPDATE_AUTO}
              data-testid={FW_UPDATE_AUTO}
              label={
                isBinaryApp ? (
                  pageData.auto_update_option.label
                ) : (
                  <strong>{pageData.auto_update_option.label}</strong>
                )
              }
            />
            <div className={classes.radioText}>
              {pageData.auto_update_option.description}
            </div>
            <RadioButton
              checked={selectedRadioButton === FW_UPDATE_NOTIFY}
              className={classes.radioButton}
              key="notify_button"
              value={FW_UPDATE_NOTIFY}
              data-testid={FW_UPDATE_NOTIFY}
              label={
                isBinaryApp ? (
                  pageData.notify_option.label
                ) : (
                  <strong>{pageData.notify_option.label}</strong>
                )
              }
            />
          </RadioButtons>
          <div className={classes.radioText}>
            {pageData.notify_option.description}
          </div>
        </div>
        <div className={classes.footer}>
          <NextStageButton
            appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
            onClick={selectFirmwareUpdate}
            disabled={selectedRadioButton === null}
            dataTestId="btn-apply"
            copy={pageData.apply_button_text}
          />
        </div>
      </div>
    </>
  )
}

export default FirmwareUpdateChoicePage
