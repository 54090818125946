import { useCallback, useContext, useState } from 'react'
import { ConfigContext, UserContext } from '@/store'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Logger } from '@/utils/Logger'

const useCreateAccount = () => {
  const configCtx = useContext(ConfigContext)
  const { accountType, getSessionId, parentAccountResourceId } = useContext(
    UserContext
  )
  const [success, setSuccess] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)
  const {
    onbpReleaseTenantlesstokensupport: isTenantlessTokenSupported
  } = useFlags()

  // Need a way to reset the state of the hook for a clean retry
  const resetState = () => {
    setError(null)
    setSuccess(null)
    setInProgress(false)
  }

  const _performTokenExchange = useCallback(
    async (accountId) => {
      await configCtx.setTenant({
        tenantId: accountId,
        authContext: 'tenant',
        preventReload: true
      })
    },
    [configCtx.setTenant]
  )

  const _parseAccountMgmtResponse = (err) => {
    try {
      const accountError = err.response.data.errors.pop().code
      return `UA_${err.response.status}_${accountError}_CA`
    } catch {
      return null
    }
  }

  const _parseStratusAuthZResponse = (err) => {
    try {
      const tokenExchangeError = err.response.data.details.error_description.split(
        ':'
      )[0]
      return `SA_${err.response.status}_${tokenExchangeError}_TE`
    } catch {
      return null
    }
  }

  const createAccount = useCallback(
    async ({
      accountName = null,
      organizationDescription = null,
      accountId = null,
      country = configCtx.country,
      orgId = null
    } = {}) => {
      setInProgress(true)
      // accountId is the case of selecting an existing domain therefore there
      // is no need to call Account Mgt Svc. Only perform token exchange.
      if (accountId) {
        await _performTokenExchange(accountId)
        setSuccess(true)
        setInProgress(false)
        return
      }
      const idToken = await configCtx.authProvider.getIDToken()
      const accountClient = new AccountMgtSvcClient(
        configCtx.stack,
        isTenantlessTokenSupported
          ? configCtx.authProvider.createOrglessAuthProvider()
          : configCtx.authProvider
      )
      await accountClient
        .createAccount(
          {
            language: configCtx.language,
            countrySet: [country],
            idToken,
            accountName,
            accountId: orgId,
            description: organizationDescription,
            type: accountType,
            parentAccountResourceId
          },
          { 'X-Correlation-ID': getSessionId() }
        )
        .then(async (response) => {
          // Reject response if resourceId is not provided
          if (!response?.data?.resourceId) {
            const err = new Error()
            err.response = response
            throw err
          }
          // Tenancy changes introduced new stratus_id token parameter in which
          // relates to user account having had been onboarded. For brand new
          // accounts we need to force token refresh in order to yield a new
          // token containing stratus_id before token exchange.
          await configCtx.authProvider.getAccessToken(true)
          await _performTokenExchange(response.data.resourceId)
          setSuccess(true)
          setInProgress(false)
        })
        .catch((err) => {
          Logger.error('createAccount error:', err)
          let errorCode = _parseAccountMgmtResponse(err)
          if (!errorCode) errorCode = _parseStratusAuthZResponse(err)
          if (!errorCode)
            errorCode = `UA_${err.response?.status || 'XXX'}_OPCA00001_CA`

          setError(errorCode)
          setSuccess(false)
          setInProgress(false)
        })
    },
    [
      accountType,
      configCtx.authProvider,
      configCtx.country,
      configCtx.language,
      configCtx.stack,
      getSessionId,
      isTenantlessTokenSupported,
      _performTokenExchange
    ]
  )

  return {
    createAccount,
    success,
    error,
    inProgress,
    resetState
  }
}

export default useCreateAccount
