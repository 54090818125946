import React, { useEffect, useMemo, useState } from 'react'
import useContentStack from '@/hooks/useContentStack'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_TERTIARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import { GENERIC_MODAL_TYPES, LAYOUT_TYPES } from './GenericModal'
import useAnalytics from '@/hooks/useAnalytics'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import classes from './GenericAccordionModal.module.scss'
import Accordion from '@veneer/core/dist/scripts/accordion'
import { IconChevronLeft } from '@veneer/core/dist/scripts/icons'
import Scrollbar from '@veneer/core/dist/scripts/scrollbar'
import { getButtonCopy } from '@/hooks/utils'
const { BUTTONS, SCREENS, ACCORDIONS } = ANALYTICS

const GenericAccordionModal = ({
  children,
  setVisibility,
  setOverride,
  setCustomLayout,
  wrapper
}) => {
  const analyticsIndex = wrapper.toUpperCase()

  const hpPlusOverviewAnalytics = useAnalytics(SCREENS.HP_PLUS_OVERVIEW_MODAL)
  const hpPlusReqAnalytics = useAnalytics(SCREENS.HP_PLUS_REQUIREMENTS_MODAL)
  const hpSmartAdvanceAnalytics = useAnalytics(
    SCREENS.HP_SMART_ADVANCE_OVREVIEW_MODAL
  )
  const hpSmartProAnalytics = useAnalytics(SCREENS.HP_SMART_PRO_OVERVIEW_MODAL)
  const instantInkAnalytics = useAnalytics(SCREENS.INSTANT_INK_OVERVIEW_MODAL)
  const smartSecurityAnalytics = useAnalytics(
    SCREENS.SMART_SECURITY_OVERVIEW_MODAL
  )
  const forestFirstPrintingAnalytics = useAnalytics(
    SCREENS.FOREST_FIRST_PRINTING_OVERVIEW_MODAL
  )
  const cmsTypeAlt = Object.keys(GENERIC_MODAL_TYPES).find(
    (x) => GENERIC_MODAL_TYPES[x] === wrapper
  )
  const cmsType = CONTENT_STACK_TYPES[cmsTypeAlt.toLowerCase()]

  useEffect(() => {
    setCustomLayout(LAYOUT_TYPES.ACCORDION_LAYOUT)
    MODAL_ANALYTICS[analyticsIndex].display()
  }, [])

  const { pageData } = useContentStack({
    content_type: cmsType
  })

  const MODAL_ANALYTICS = {
    LEARN_MORE_HP_PLUS_OVERVIEW: {
      display: hpPlusOverviewAnalytics.fireModalWindowDisplayed,
      buttonClick: hpPlusOverviewAnalytics.fireButtonClick,
      accordionClick: hpPlusOverviewAnalytics.fireAccordionClick,
      accordions: [
        ACCORDIONS.WHAT_IS_HP_PLUS,
        ACCORDIONS.IS_HP_SUBS,
        ACCORDIONS.IS_THERE_COST,
        ACCORDIONS.CAN_CANCEL,
        ACCORDIONS.HP_VS_II,
        ACCORDIONS.CAN_ACTIVATE_LATER,
        ACCORDIONS.HW_SETUP
      ]
    },
    LEARN_MORE_HP_PLUS_REQUIREMENTS: {
      display: hpPlusReqAnalytics.fireModalWindowDisplayed,
      buttonClick: hpPlusReqAnalytics.fireButtonClick,
      accordionClick: hpPlusReqAnalytics.fireAccordionClick,
      accordions: [
        ACCORDIONS.WHAT_ARE_REQS,
        ACCORDIONS.WHAT_IS_ORG_HP_INK,
        ACCORDIONS.WHAT_IF_NON_ORG_HP_INK,
        ACCORDIONS.WHY_KEEP_PRINTER_CONNECTED,
        ACCORDIONS.WHAT_IF_DISCONNECTED,
        ACCORDIONS.CONNECTION_USING_USB
      ]
    },
    LEARN_MORE_HP_SMART_ADVANCE: {
      display: hpSmartAdvanceAnalytics.fireModalWindowDisplayed,
      buttonClick: hpSmartAdvanceAnalytics.fireButtonClick
    },
    LEARN_MORE_HP_SMART_PRO: {
      display: hpSmartProAnalytics.fireModalWindowDisplayed,
      buttonClick: hpSmartProAnalytics.fireButtonClick
    },
    LEARN_MORE_INSTANT_INK: {
      display: instantInkAnalytics.fireModalWindowDisplayed,
      buttonClick: instantInkAnalytics.fireButtonClick,
      accordionClick: instantInkAnalytics.fireAccordionClick,
      accordions: [
        ACCORDIONS.WHAT_IS_II,
        ACCORDIONS.HOW_II_WORKS,
        ACCORDIONS.WHEN_RECEIVE_II,
        ACCORDIONS.PLANS_AND_COST_II,
        ACCORDIONS.CANCEL_II,
        ACCORDIONS.CREDIT_CARD_REQ_II
      ]
    },
    LEARN_MORE_SMART_SECURITY: {
      display: smartSecurityAnalytics.fireModalWindowDisplayed,
      buttonClick: smartSecurityAnalytics.fireButtonClick
    },
    LEARN_MORE_FOREST_FIRST: {
      display: forestFirstPrintingAnalytics.fireModalWindowDisplayed,
      buttonClick: forestFirstPrintingAnalytics.fireButtonClick
    }
  }

  const closeClickHandler = () => {
    MODAL_ANALYTICS[analyticsIndex].buttonClick(BUTTONS.CLOSE)
    setVisibility(false)
  }
  const backClickHandler = () => {
    MODAL_ANALYTICS[analyticsIndex].buttonClick(BUTTONS.BACK)
    setOverride(GENERIC_MODAL_TYPES.LEARN_MORE_MODAL)
  }

  const linkClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      if (cmsType === CONTENT_STACK_TYPES.learn_more_hp_plus_overview_modal) {
        setOverride(
          GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_OVERVIEW_SAVE_ON_INK_MODAL
        )
      }
    }
  }

  const accordionClickHandler = (item, expanded) => {
    MODAL_ANALYTICS[analyticsIndex].accordionClick(item, expanded)
  }

  return (
    <>
      {children({
        buttonClicks: [
          closeClickHandler,
          backClickHandler,
          linkClickHandler,
          accordionClickHandler
        ],
        data: pageData,
        analytics: MODAL_ANALYTICS,
        analyticsIndex: analyticsIndex
      })}
    </>
  )
}

const AccordionBuilder = ({
  data,
  linkClickHandler,
  accordionClickHandler,
  analytics,
  analyticsIndex
}) => {
  const [accordionItems, setAccordionItems] = useState([])

  useEffect(() => {
    if (accordionItems.length < 1 && data) {
      setAccordionItems(
        data.map((item, i) => {
          return {
            content: (
              <div
                data-testid={'accordion-content-' + i}
                onClick={linkClickHandler}
                dangerouslySetInnerHTML={{
                  __html: item?.accordion_item?.content
                }}
              />
            ),
            expanded: data?.length === 1,
            header: {
              centralArea: (
                <div
                  data-testid={'accordion-header-' + i}
                  dangerouslySetInnerHTML={{
                    __html: item?.accordion_item?.title
                  }}
                />
              )
            },
            id: 'accordionItem-' + i
          }
        })
      )
    }
  }, [data, accordionItems, setAccordionItems])

  const handleAccordionEvent = (index, expanded) => {
    const updatedAccordionItems = [...accordionItems]
    updatedAccordionItems[index].expanded = expanded
    setAccordionItems(updatedAccordionItems)
    {
      if (accordionItems.length > 1)
        accordionClickHandler(
          analytics[analyticsIndex].accordions[index],
          expanded
        )
    }
  }

  return (
    <Scrollbar className={classes.scrollbar}>
      <Accordion
        items={accordionItems}
        id="accordion-items"
        onCollapse={(_, i) => {
          handleAccordionEvent(i, false)
        }}
        onExpand={(_, i) => {
          handleAccordionEvent(i, true)
        }}
      />
    </Scrollbar>
  )
}

export const AccordionLayout = ({
  buttonClicks,
  data,
  analytics,
  analyticsIndex
}) => {
  const [
    closeClickHandler,
    backClickHandler,
    linkClickHandler,
    accordionClickHandler
  ] = buttonClicks

  const { primaryButtonCopy, backButtonCopy } = useMemo(
    () => getButtonCopy(data),
    [data]
  )

  return (
    <Modal
      id="generic-modal"
      data-testid="generic-modal"
      className={classes.learnMoreHpPlusOverviewModal}
      show={true}
      closeOnBlur={false}
      footer={
        <div className={classes.closeButtonContainer}>
          <Button
            data-testid="close-button"
            appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
            onClick={closeClickHandler}
            className={classes.closeButton}
          >
            {primaryButtonCopy}
          </Button>
        </div>
      }
      alignFooter="end"
    >
      <Button
        appearance={VENEER_TERTIARY_BUTTON_APPEARANCE}
        onClick={backClickHandler}
        data-testid="back-button"
        className={classes.backButton}
      >
        <IconChevronLeft color="colorHpBlue6" />
        <div className={classes.backButtonCopy}>{backButtonCopy}</div>
      </Button>
      <div data-testid="header" className={classes.header}>
        {data?.header}
        {data?.uid ===
          CONTENT_STACK_TYPES.learn_more_instant_ink_modal.entry_uid && (
          <img
            src={data?.header_image?.url}
            className={classes.headerImage}
            data-testid={`image-${data?.header_image?.url}`}
          />
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: data?.body
        }}
      />
      {data?.page_content[0]?.accordion_item && (
        <AccordionBuilder
          data={data?.page_content}
          linkClickHandler={linkClickHandler}
          accordionClickHandler={accordionClickHandler}
          analytics={analytics}
          analyticsIndex={analyticsIndex}
        />
      )}
    </Modal>
  )
}

export default GenericAccordionModal
