import { useCallback, useContext, useEffect, useState } from 'react'
import { ConfigContext, PrinterContext } from '@/store'
import useDeviceAuthGrantApi from '@/hooks/useDeviceAuthGrantApi'
import { usePrinterSystemConfig } from '@/hooks/DeviceHttpProxy/usePrinterSystemConfig'
import { Logger } from '@/utils/Logger'
import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'

export function useAppConfig() {
  const { isBinaryApp } = useContext(ConfigContext)
  const { readyToSendRequest } = useDeviceHttpProxy()
  const {
    productNumber: localProductNumber,
    setProductNumber,
    deviceCapabilities
  } = useContext(PrinterContext)
  const [initialized, setInitialized] = useState(false)
  const { fetchPrinterDetails } = useDeviceAuthGrantApi()
  const { getProductNumber } = usePrinterSystemConfig()

  const getPrinterDetails = useCallback(async () => {
    if (!localProductNumber || !deviceCapabilities) {
      try {
        const productNumber = await getProductNumber()
        await fetchPrinterDetails(productNumber)
        setProductNumber(productNumber)
      } catch (error) {
        Logger.error('Error trying to get printer details printer', error)
      }
    }
  }, [
    getProductNumber,
    fetchPrinterDetails,
    localProductNumber,
    setProductNumber,
    deviceCapabilities
  ])

  useEffect(() => {
    if (isBinaryApp && readyToSendRequest && !initialized) {
      setInitialized(true)
      getPrinterDetails()
    }
  }, [getPrinterDetails, isBinaryApp, readyToSendRequest, initialized])
}
