import {
  getResourceTypeElement,
  getManifestURI,
  buildManifestUri,
  checkMethodExists,
  getManifestResourceNodesElements,
  getManifestResourceMap,
  findResourceNodeByTypeKeyAndUri
} from './ManifestUtils'
import {
  getSupportedTreeElements,
  getSupportedIfcElements
} from './DiscoveryTreeUtils'
import { LedmError } from './LedmError'
import { getElementTextContent, getResourceUriElement } from './ElementUtils'

export function getLedmResourceUriFromDiscoveryTree(
  discoveryTree,
  resourceType
) {
  const supportedTreeElements = getSupportedTreeElements(discoveryTree)
  const supportedTree = supportedTreeElements.find(
    (element) =>
      getElementTextContent(getResourceTypeElement(element)) === resourceType
  )

  if (!supportedTree) {
    throw new LedmError(
      `Resource URI with Resource Type "${resourceType}" not found`,
      discoveryTree
    )
  }

  return getElementTextContent(getResourceUriElement(supportedTree))
}

export function getManifestUriByResourceType(discoveryTree, resourceType) {
  const supportedIfcList = getSupportedIfcElements(discoveryTree)
  const supportedIfc = supportedIfcList.find(
    (element) =>
      getElementTextContent(getResourceTypeElement(element)) === resourceType
  )

  if (!supportedIfc) {
    throw new LedmError(
      `Manifest ${resourceType} wasn't found on Discovery Tree`,
      discoveryTree
    )
  }

  return getManifestURI(supportedIfc)
}

export function getLedmURIFromManifest({
  manifest,
  uriResourceTypeKey,
  nodes,
  method
}) {
  const lastUri = nodes[nodes.length - 1]

  const resourceNodeElement = nodes.reduce(
    (resourceNodeOrResourceMap, node) => {
      const resourceNodes = getManifestResourceNodesElements(
        resourceNodeOrResourceMap
      )

      const resourceNode = findResourceNodeByTypeKeyAndUri(
        resourceNodes,
        uriResourceTypeKey,
        node
      )

      if (!resourceNode) {
        throw new LedmError(
          `Error trying to get resource node with type "${node.resourceType}" and uri "${node.resourceUri}" on resource node or resource map`,
          manifest
        )
      }

      return resourceNode
    },
    getManifestResourceMap(manifest)
  )

  if (!checkMethodExists(resourceNodeElement, method)) {
    throw new LedmError(
      `Method "${method}" not supported for resource type "${lastUri.resourceType}"`,
      manifest
    )
  }
  return buildManifestUri(resourceNodeElement)
}
