import React, { useContext, useState } from 'react'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import classes from './NamePrinter.module.scss'
import { PrinterContext, ConfigContext, UserContext } from '@/store'
import TextBox from '@veneer/core/dist/scripts/text_box'
import useAnalytics from '@/hooks/useAnalytics'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'

const { BUTTONS, MODES, SCREENS } = ANALYTICS

const NamePrinterPage = () => {
  const { navigation, nextStage, isScanSetup } = useContext(ConfigContext)
  const { setName, setLocation, isHpPlus, isSingleSku } = useContext(
    PrinterContext
  )
  const { getSessionId } = useContext(UserContext)
  const [enteredName, setEnteredName] = useState(null)
  const [enteredLocation, setEnteredLocation] = useState(null)

  const {
    fireScreenDisplayed,
    fireButtonClick,
    buildButtonClickEvent,
    fireBatchEvents
  } = useAnalytics(SCREENS.PRINTER_NAME)
  fireScreenDisplayed()

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.printer_name_location
  })

  const navigate = () => {
    if (isScanSetup) {
      const output = {
        user: {
          selectedDeviceName: enteredName,
          selectedDeviceLocation: enteredLocation
        }
      }
      nextStage({
        result: 'success',
        output,
        xCorrelationId: getSessionId()
      })
    } else {
      if (isSingleSku) return navigation.push('/hp-plus-benefits')
      if (isHpPlus) return navigation.push('/activation')
      return navigation.push('/activating')
    }
  }

  const submitHandler = () => {
    setName(enteredName)
    setLocation(enteredLocation)
    fireBatchEvents([
      buildButtonClickEvent(BUTTONS.CONTINUE),
      buildButtonClickEvent(BUTTONS.PRINTER_LOC, {
        detail: MODES.PROVIDED[!!enteredLocation]
      }),
      buildButtonClickEvent(BUTTONS.PRINTER_NAME, {
        detail: MODES.PROVIDED[!!enteredName]
      })
    ])
    navigate()
  }

  const skipHandler = () => {
    fireButtonClick(BUTTONS.SKIP)
    navigate()
  }

  if (!pageData) return <></>

  return (
    <div data-testid="name-printer-page">
      <TextHeader>{pageData.header}</TextHeader>
      <TextSubHeader>{pageData.subhead}</TextSubHeader>
      <div className={classes.inputs}>
        <div className={classes.textContainer}>
          <TextBox
            id="printer-name"
            onChange={setEnteredName}
            className={classes.textBox}
            helperTextVisibility="visible"
            maxLength={32}
            label={pageData.name_label}
            helperText={pageData.name_helper_text}
          />
        </div>
        <div className={classes.textContainer}>
          <TextBox
            id="location"
            onChange={setEnteredLocation}
            className={classes.textBox}
            helperTextVisibility="visible"
            maxLength={32}
            label={pageData.location_label}
            helperText={pageData.location_helper_text}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
          onClick={skipHandler}
          data-testid="name-printer-skip"
        >
          {pageData.buttons.skip}
        </Button>
        <Button
          disabled={!enteredName && !enteredLocation}
          onClick={submitHandler}
          data-testid="name-printer-continue"
        >
          {pageData.buttons.continue}
        </Button>
      </div>
    </div>
  )
}

export default NamePrinterPage
