import React, { useContext, useEffect, useRef, useState } from 'react'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Button from '@veneer/core/dist/scripts/button'
import {
  CONTENT_STACK_TYPES,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import classes from './PartnerLinkPage.module.scss'
import { IconCheckmark, IconInfo } from '@veneer/core/dist/scripts/icons'
import useContentStack from '@/hooks/useContentStack'
import { PartnerLinkClient } from '@/clients/PartnerLinkClient'
import { Logger } from '@/utils/Logger'

const STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  LOADING: 'loading'
}

const PartnerLinkPage = () => {
  const { nextStage, authProvider, stack } = useContext(ConfigContext)
  const { onError } = useContext(ErrorContext)
  const { productNumber, serialNumber, cloudId } = useContext(PrinterContext)
  const { getSessionId } = useContext(UserContext)
  const apiClient = useRef(null)

  const [partnerLink, setPartnerLink] = useState('')
  const [status, setStatus] = useState(null)

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.partner_link
  })

  const connectHandler = () => {
    setStatus(STATUS.LOADING)
    apiClient.current
      .connect({
        productNumber,
        serialNumber,
        deviceId: cloudId,
        accountId: partnerLink
      })
      .then(() => {
        setStatus(STATUS.SUCCESS)
      })
      .catch((error) => {
        Logger.error('connect (PartnerLink) error:', error)
        setStatus(STATUS.FAILURE)
        onError({
          err: `PL_${error?.status || 400}_PTLK00001_SB`,
          stg: STAGES.generic
        })
      })
  }

  const notRightNowHandler = () => {
    nextStage({
      result: 'declined',
      xCorrelationId: getSessionId()
    })
  }

  const continueHandler = () => {
    nextStage({
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  useEffect(() => {
    if (!apiClient.current) {
      apiClient.current = new PartnerLinkClient(stack, authProvider)
    }
  }, [])

  if (!pageData) return <></>

  return (
    <div className={classes.partnerLinkPage} data-testid="partner-link-page">
      <TextHeader>{pageData.header}</TextHeader>
      <TextSubHeader dangerouslySetInnerHTML={{ __html: pageData.body }} />
      <div className={classes.textContainer}>
        <TextBox
          id="partner-link"
          className={classes.partnerLinkInput}
          label={pageData.input_label}
          onChange={setPartnerLink}
          maxLength={50}
          disabled={status === STATUS.SUCCESS}
          required={true}
        />
        {status === STATUS.SUCCESS && (
          <span className={classes.connected} data-testid="success-checkmark">
            <IconCheckmark filled={true} size={18} /> {pageData.connected_label}
          </span>
        )}
        {status === STATUS.LOADING && (
          <img
            className={classes.loadingSpinner}
            src={pageData.loading_spinner.url}
            alt="Loading..."
          />
        )}
      </div>
      <span className={classes.inputHelpText}>
        <IconInfo filled size={20} /> {pageData.input_help_text}
      </span>
      <div className={classes.footer}>
        <Button
          data-testid="not-right-now-button"
          disabled={status === STATUS.SUCCESS}
          appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
          onClick={notRightNowHandler}
        >
          {pageData.buttons.secondary_button}
        </Button>
        {status === STATUS.SUCCESS ? (
          <Button data-testid="continue-button" onClick={continueHandler}>
            {pageData.buttons.primary_button_step_2}
          </Button>
        ) : (
          <Button
            data-testid="connect-button"
            disabled={partnerLink.length < 1}
            onClick={connectHandler}
          >
            {pageData.buttons.primary_button_step_1}
          </Button>
        )}
      </div>
    </div>
  )
}

export default PartnerLinkPage
