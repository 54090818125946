import React, { useContext, useEffect, useState } from 'react'
import classes from './FirstPrintPage.module.scss'
import useContentStack from '@/hooks/useContentStack'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  HP_SMART_SOFTWARE_URL
} from '@/store/Constants'
import Button from '@veneer/core/dist/scripts/button'
import TextHeader from '@/components/UI/TextHeader'
import FirstPrintButton from '@/components/FinishSetup/FirstPrintButton'
import useAnalytics from '@/hooks/useAnalytics'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import clsx from 'clsx'

const FirstPrintPage = ({ history }) => {
  const { isHpPlus, isMono, isLargeFormat } = useContext(PrinterContext)
  const { isWindows, nextStage, isMobile, isAndroid, driverUrl } = useContext(
    ConfigContext
  )
  const { getSessionId } = useContext(UserContext)
  const [testPageImage, setTestPageImage] = useState('')

  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(ANALYTICS.SCREENS.PRINT_TEST_PAGE)

  const parseCMSData = (data) => {
    return {
      header: data?.header,
      body_text: data?.body_text,
      test_page_images: data?.test_page_images,
      print_test_page_button_text: data?.print_test_page_button_text,
      information_image: data?.information.image,
      information_text: data?.information.text,
      continue_button_text: data?.continue_button_text,
      mobile: data?.android
    }
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.first_print,
    parsing_function: parseCMSData
  })

  const driverInstallHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(ANALYTICS.LINKS.INSTALL_DRIVER)
      isWindows
        ? window.open(driverUrl, '_blank')
        : window.open(HP_SMART_SOFTWARE_URL, '_blank')
    }
  }

  const continueHandler = () => {
    nextStage({
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  useEffect(() => {
    if (pageData?.test_page_images) {
      const imageEntry = pageData.test_page_images.find(
        (imgEntry) =>
          imgEntry.is_hp_plus === (isHpPlus || false) &&
          imgEntry.is_mono === (isMono || false)
      )
      const imageEntryLargeFormat = pageData.test_page_images.find(
        (imgEntry) => isLargeFormat && imgEntry.is_large_format
      )
      setTestPageImage(isLargeFormat ? imageEntryLargeFormat : imageEntry)
    }
  }, [isHpPlus, isMono, pageData, isLargeFormat])

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div className={classes.firstPrintPage} data-testid="first-print-page">
      <TextHeader>{pageData.header}</TextHeader>
      <div className={classes.content}>
        <img
          className={classes.previewImage}
          data-testid="first-print-preview-image"
          src={testPageImage?.preview_image?.url || testPageImage?.image?.url}
        />
        <div className={classes.rightSection}>
          <div
            className={clsx({ [classes.mobileDescription]: isMobile })}
            dangerouslySetInnerHTML={{
              __html: isMobile ? pageData.mobile.body_text : pageData.body_text
            }}
          />
          <div className={classes.printButton}>
            <FirstPrintButton
              isMobile={isMobile}
              isAndroid={isAndroid}
              fireButtonClick={fireButtonClick}
              copy={
                isMobile
                  ? pageData.mobile.download_test_page_button
                  : pageData.print_test_page_button_text
              }
              image={testPageImage}
            />
          </div>
          <div className={classes.info}>
            <IconInfo filled={true} color="colorGray5" />
            <p
              data-testid="install-driver"
              onClick={driverInstallHandler}
              dangerouslySetInnerHTML={{
                __html: pageData.information_text
              }}
            />
          </div>
        </div>
      </div>
      <Button
        className={classes.continueButton}
        data-testid="continue"
        onClick={() => {
          fireButtonClick(ANALYTICS.BUTTONS.CONTINUE)
          continueHandler()
        }}
      >
        {pageData.continue_button_text}
      </Button>
    </div>
  )
}

export default FirstPrintPage
