import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { ConfigContext } from '@/store/ConfigContext'
import {
  CDM_DEVICE,
  CDM_DISCOVERY_TREE,
  DHP_REQUEST_STATUS,
  LEDM_DISCOVERY_TREE,
  SPLUNK_RUM_CUSTOM_EVENTS,
  SPLUNK_RUM_FIELDS
} from '@/store/Constants'
import { Logger } from '@/utils/Logger'
import { WhenJWebReady } from '@jarvis/jweb-core'
import {
  parseDhpResponseData,
  getManifestUriByResourceType
} from '@/utils/DeviceHttpProxy'
import { useSplunkRum } from '@/hooks/useSplunkRum'

export class DhpError extends Error {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}

const XML_HEADER = {
  'Content-Type': 'text/xml'
}

const factoryDhpResponse = (status, data = undefined) => ({
  data,
  status
})

export const DeviceHttpProxyContext = React.createContext({
  isDhpInitialized: false,
  isCdm: false,
  fetch: EMPTY_FUNCTION,
  printerServices: factoryDhpResponse(DHP_REQUEST_STATUS.NOT_STARTED),
  getManifest: EMPTY_FUNCTION
})

export function DeviceHttpProxyProvider({ children }) {
  const { sessionContext, isBinaryApp, appSessionId } = useContext(
    ConfigContext
  )

  const [printerServices, setPrinterServices] = useState(
    factoryDhpResponse(DHP_REQUEST_STATUS.NOT_STARTED, undefined)
  )
  const [isDhpInitialized, setIsDhpInitialized] = useState(false)
  const [manifests, setManifests] = useState(new Map())
  const deviceHttpProxy = useRef(null)

  const { publishSpanEvent } = useSplunkRum(SPLUNK_RUM_CUSTOM_EVENTS.DHP_ERRORS)

  const isCdm = useMemo(
    () => sessionContext?.device?.fwProtocolCapability === CDM_DEVICE,
    [sessionContext]
  )

  const initDeviceHttpProxy = useCallback(async () => {
    const jWebInterface = await WhenJWebReady
    deviceHttpProxy.current = jWebInterface.Plugins.DeviceHttpProxy
    if (!deviceHttpProxy.current) {
      throw new Error('JWeb plugin: DeviceHttpProxy not available')
    }
  }, [])

  const fetch = useCallback(
    async (dhpRequestPayload) => {
      const isLedmOrHybrid = !isCdm
      try {
        Logger.log('Calling DHP', dhpRequestPayload)
        const response = await deviceHttpProxy.current.sendRequest({
          ...dhpRequestPayload,
          sessionId: appSessionId,
          allowUserInteraction: true,
          ...(isLedmOrHybrid && {
            headers: {
              ...dhpRequestPayload.headers,
              ...XML_HEADER
            }
          })
        })

        if (!/2\d{2}/.test(response?.statusCode)) {
          throw new DhpError('Unknown DHP response', response)
        }

        Logger.log('DHP response', dhpRequestPayload, response)
        return parseDhpResponseData(response)
      } catch (error) {
        publishSpanEvent({
          [SPLUNK_RUM_FIELDS.DHP_PATH]: dhpRequestPayload.path
        })
        Logger.error(
          `DHP fails trying to fetch ${dhpRequestPayload.path}`,
          dhpRequestPayload,
          error
        )
        throw error
      }
    },
    [isCdm, appSessionId, publishSpanEvent]
  )

  const getManifest = useCallback(
    async (manifestResourceType) => {
      if (manifests.has(manifestResourceType)) {
        return manifests.get(manifestResourceType)
      }
      const path = getManifestUriByResourceType(
        printerServices.data,
        manifestResourceType
      )
      const { body } = await fetch({
        path,
        method: 'GET'
      })

      setManifests(
        (oldManifests) =>
          new Map(oldManifests.set(manifestResourceType, body.data))
      )

      return body.data
    },
    [manifests, fetch, printerServices]
  )

  const fetchPrinterServices = useCallback(() => {
    const path = isCdm ? CDM_DISCOVERY_TREE : LEDM_DISCOVERY_TREE
    return fetch({ path, method: 'GET' })
  }, [fetch, isCdm])

  useEffect(() => {
    if (
      appSessionId &&
      isBinaryApp &&
      isCdm !== undefined &&
      printerServices.status === DHP_REQUEST_STATUS.NOT_STARTED &&
      isDhpInitialized
    ) {
      setPrinterServices(factoryDhpResponse(DHP_REQUEST_STATUS.PENDING))
      fetchPrinterServices()
        .then((response) => {
          Logger.log('fetchPrinterServices result', response.body.data)
          setPrinterServices(
            factoryDhpResponse(DHP_REQUEST_STATUS.SUCCESS, response.body.data)
          )
        })
        .catch((e) => {
          setPrinterServices(factoryDhpResponse(DHP_REQUEST_STATUS.FAILED))
          Logger.error('Error trying to get printer services', e)
        })
    }
  }, [
    fetchPrinterServices,
    isBinaryApp,
    isCdm,
    setPrinterServices,
    appSessionId,
    printerServices,
    isDhpInitialized
  ])

  useEffect(() => {
    if (isBinaryApp && appSessionId && !isDhpInitialized) {
      initDeviceHttpProxy()
        .catch((error) => {
          Logger.error('Error during DHP initialization', error)
        })
        .finally(() => {
          setIsDhpInitialized(true)
        })
    }
  }, [isBinaryApp, appSessionId, isDhpInitialized, initDeviceHttpProxy])

  const dhpContextValue = useMemo(
    () => ({
      isDhpInitialized,
      isCdm,
      fetch,
      printerServices,
      getManifest
    }),
    [isDhpInitialized, isCdm, fetch, printerServices, getManifest]
  )

  return (
    <DeviceHttpProxyContext.Provider value={dhpContextValue}>
      {children}
    </DeviceHttpProxyContext.Provider>
  )
}
