export class CdmError extends Error {
  constructor(message, serviceDiscovery) {
    super(message)
    this.serviceDiscovery = JSON.stringify(serviceDiscovery)
  }
}

function getServiceByGun(services, serviceGun) {
  return services.find((service) => service.serviceGun === serviceGun)
}

function getLinkByRel(links, rel) {
  return links.find((link) => link.rel === rel)
}

function checkMethodExists(hints, method) {
  return hints.some(
    (hint) => hint.method.toUpperCase() === method.toUpperCase()
  )
}

export function getCdmHrefByServiceRelAndMethod({
  servicesDiscovery,
  serviceGun,
  rel,
  method
}) {
  const service = getServiceByGun(servicesDiscovery.services, serviceGun)
  if (!service) {
    throw new CdmError(
      `Service Gun "${serviceGun}" not found`,
      servicesDiscovery
    )
  }

  const link = getLinkByRel(service.links, rel)
  if (!link) {
    throw new CdmError(`Link about rel "${rel}" not found`, servicesDiscovery)
  }

  if (!checkMethodExists(link.hints, method)) {
    throw new CdmError(
      `Hint with method "${method}" doesn't exist on the service "${serviceGun}" in relation ${rel}`,
      servicesDiscovery
    )
  }

  return link.href
}
