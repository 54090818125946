import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'
import { useCallback, useMemo } from 'react'
import { DHP_SERVICES } from '@/store/Constants'
import { Logger } from '@/utils/Logger'

export function useDeviceSetup() {
  const { sendRequest } = useDeviceHttpProxy()

  const updateActionInDeviceSetup = useCallback(
    async (prop, bodyToUpdate) => {
      try {
        const { body } = await sendRequest(DHP_SERVICES.GET_DEVICE_SETUP_STATUS)

        if (prop in body.data) {
          await sendRequest(DHP_SERVICES.SET_DEVICE_SETUP_ACTION, {
            version: body.data.version,
            ...bodyToUpdate
          })
        }
      } catch (error) {
        Logger.error(`Error updating ${prop} in device setup:`, error)
      }
    },
    [sendRequest]
  )

  return useMemo(() => ({ updateActionInDeviceSetup }), [
    updateActionInDeviceSetup
  ])
}
