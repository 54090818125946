import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import TextBox from '@veneer/core/dist/scripts/text_box'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import classes from './AssignOrganization.module.scss'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import RadioButtons from '@veneer/core/dist/scripts/radio_buttons'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import TextArea from '@veneer/core/dist/scripts/text_area'
import Select from '@veneer/core/dist/scripts/select'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useAnalytics from '@/hooks/useAnalytics'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  ISO_VALUES,
  SMB_EXPERIENCE
} from '@/store/Constants'
import NextStageButton from '@/components/UI/NextStageButton'
import { IconMinusCircle, IconWarningAlt } from '@/styles/LazyVeneer'
import SupportedCountriesModal from '@/components/UI/Modals/SupportedCountriesModal'
import useContentStack from '@/hooks/useContentStack'

const { MODES, BUTTONS, SCREENS, LINKS } = ANALYTICS

const AssignOrganizationPage = ({
  createAccount,
  availableOrganizations,
  doDomainsExist,
  didDomainLoad,
  setRetryBehavior
}) => {
  const {
    onbpReleaseOrgcountryddassignprinterowner: isCountrySelectorEnabled
  } = useFlags()
  const { onError } = useContext(ErrorContext)
  const userCtx = useContext(UserContext)
  const { country, hpPlusCountries, experience, sessionContext } = useContext(
    ConfigContext
  )

  const { isHpPlus } = useContext(PrinterContext)
  const [showContent, setShowContent] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [enteredOrganization, setEnteredOrganization] = useState('')
  const [isOrganizationNameValid, setIsOrganizationNameValid] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [hpPlusCountrySelected, setHpPlusCountrySelected] = useState(false)
  const [countrySelectorEval, setCountrySelectorEval] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(country.toUpperCase())
  const [
    showSupportedCountriesModal,
    setShowSupportedCountriesModal
  ] = useState(false)
  const [
    enteredOrganizationDescription,
    setEnteredOrganizationDescription
  ] = useState(null)
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.COMPANY_NAME
  )
  const analyticsUnsupportedCountryError = useAnalytics(
    SCREENS.COUNTRY_UNSUPPORTED_ERROR,
    false
  )
  const analyticsUnsupportedCountryErrorList = useAnalytics(
    SCREENS.COUNTRY_UNSUPPORTED_ERROR_SUPPORTED_LIST,
    false
  )
  const [retry, setRetry] = useState(false)

  const parseCmsResponse = (data) => ({
    header: data.header,
    subheader: data.subheader,
    new_organization: data.organization_labels.new_organization_text,
    organization_name: data.organization_labels.name,
    invalid_name: data.organization_labels.invalid_name_text,
    organization_country: data.organization_labels.country,
    country_error: data.organization_labels.country_error_text,
    organization_description: data.organization_labels.description,
    agree_terms: data.agreement_terms,
    continue_button_text: data.continue_button_text
  })
  const productFamily = sessionContext?.onboardingContext?.productFamily
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.assign_organization,
    parsing_function: parseCmsResponse,
    additional_params: {
      product_family: productFamily?.toUpperCase(),
      experience: experience
        ? experience.toUpperCase()
        : SMB_EXPERIENCE.toUpperCase()
    }
  })

  const fireUnsupportedCountryErrorAnalytics = useCallback(
    (cc) => {
      if (isHpPlus && hpPlusCountries?.indexOf(cc) === -1)
        analyticsUnsupportedCountryError.fireScreenDisplayed({
          mode: MODES.DOMAIN[doDomainsExist]
        })
    },
    [
      analyticsUnsupportedCountryError,
      doDomainsExist,
      isHpPlus,
      hpPlusCountries
    ]
  )

  useEffect(() => {
    if (didDomainLoad && !showContent) {
      setShowContent(true)
      fireScreenDisplayed({
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }, [didDomainLoad, doDomainsExist, fireScreenDisplayed, showContent])

  useEffect(() => {
    if (
      didDomainLoad &&
      isCountrySelectorEnabled &&
      userCtx.supportedCountries?.length > 0 &&
      !countrySelectorEval &&
      (!doDomainsExist || selectedOrganization === 'new')
    ) {
      setCountrySelectorEval(true)
      fireButtonClick(BUTTONS.SELECT_COUNTRY, {
        detail: selectedCountry,
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireUnsupportedCountryErrorAnalytics(selectedCountry)
    }
  }, [
    userCtx.supportedCountries,
    countrySelectorEval,
    didDomainLoad,
    doDomainsExist,
    selectedCountry,
    selectedOrganization,
    isCountrySelectorEnabled,
    fireUnsupportedCountryErrorAnalytics,
    fireButtonClick
  ])

  const supportedCountriesClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      analyticsUnsupportedCountryError.fireHyperLinkClick(
        LINKS.SUPPORTED_COUNTRIES,
        { mode: MODES.DOMAIN[doDomainsExist] }
      )
      setShowSupportedCountriesModal(true)
      analyticsUnsupportedCountryErrorList.fireScreenDisplayed({
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }

  const accountFailedCreationHandler = (accountDetails) => {
    setRetry(true)
    createAccount(accountDetails)
  }

  const assignOwnershipHandler = () => {
    const isExistingOrg = selectedOrganization && selectedOrganization !== 'new'

    if (doDomainsExist) {
      fireButtonClick(BUTTONS.RADIO, {
        detail: MODES.ORGANIZATION[isExistingOrg],
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }

    if (!isExistingOrg) {
      fireButtonClick(BUTTONS.ORG_NAME, {
        detail: MODES.PROVIDED[!!enteredOrganization],
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireButtonClick(BUTTONS.ORG_DESC, {
        detail: MODES.PROVIDED[!!enteredOrganizationDescription],
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }

    fireButtonClick(BUTTONS.CONTINUE, {
      mode: MODES.DOMAIN[doDomainsExist]
    })

    if (
      isCountrySelectorEnabled &&
      userCtx.supportedCountries?.length > 0 &&
      isHpPlus &&
      !hpPlusCountrySelected
    ) {
      // OP = Onboarding Platform, UCHP = Unsupported HP Country
      onError({
        err: 'OP_XXX_UHPC00001_XX',
        stg: STAGES.account,
        errorProperties: {
          mode: MODES.DOMAIN[doDomainsExist]
        }
      })
      return
    }

    let accountDetails = { country: selectedCountry }
    userCtx.setOrganizationDescription(enteredOrganizationDescription)
    userCtx.setUsingExistingDomain(!!isExistingOrg)
    if (isExistingOrg) {
      //Type coercion on purpose here as input yields string
      // noinspection EqualityComparisonWithCoercionJS
      const { accountName: selectedName } = availableOrganizations.find(
        (org) => org.nodeId == selectedOrganization
      )
      userCtx.setOrganization(selectedName)
      accountDetails.accountId = selectedOrganization
    } else {
      userCtx.setOrganization(enteredOrganization.trim())
      accountDetails.accountName = enteredOrganization
      accountDetails.organizationDescription = enteredOrganizationDescription
    }
    setRetryBehavior(() => () => accountFailedCreationHandler(accountDetails))
    createAccount(accountDetails)
  }

  const organizationEntryHandler = (orgName) => {
    orgName = orgName.substring(0, 1024)
    const regex = /^[\p{L}\p{Nd}\p{P}\p{Zs}$&+=]{1,1024}$/u
    setIsOrganizationNameValid(regex.test(orgName))
    setEnteredOrganization(orgName)
  }

  const countrySelectorHandler = useCallback(
    (selected) => {
      setSelectedCountry(selected.value)
      userCtx.setSelectedCountry(selected.label)
      userCtx.setSelectedCountryISO(selected.value)
      fireButtonClick(BUTTONS.SELECT_COUNTRY, {
        detail: selected.value,
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireUnsupportedCountryErrorAnalytics(selected.value)
    },
    [
      doDomainsExist,
      fireButtonClick,
      userCtx,
      fireUnsupportedCountryErrorAnalytics
    ]
  )

  useEffect(() => {
    userCtx.setSelectedCountry(ISO_VALUES[selectedCountry])
  })

  useEffect(() => {
    if (isHpPlus && selectedCountry) {
      setHpPlusCountrySelected(hpPlusCountries?.indexOf(selectedCountry) > -1)
    }
  }, [selectedCountry, isHpPlus, hpPlusCountries, setHpPlusCountrySelected])

  useEffect(() => {
    if (selectedCountry) {
      // No orgs available, org name provided
      if (
        !doDomainsExist &&
        enteredOrganization.trim().length > 0 &&
        isOrganizationNameValid
      ) {
        setSubmitDisabled(false)
        return
      }
      // Orgs available, existing org selected
      if (
        doDomainsExist &&
        selectedOrganization &&
        selectedOrganization !== 'new'
      ) {
        setSubmitDisabled(false)
        return
      }
      // New selected, org name provided
      if (
        selectedOrganization === 'new' &&
        enteredOrganization.trim().length > 0 &&
        isOrganizationNameValid
      ) {
        setSubmitDisabled(false)
        return
      }
    }
    setSubmitDisabled(true)
  }, [
    availableOrganizations,
    doDomainsExist,
    enteredOrganization,
    selectedCountry,
    selectedOrganization,
    isOrganizationNameValid
  ])

  useEffect(() => {
    if (userCtx.supportedCountriesErrorCode) {
      onError({
        err: userCtx.supportedCountriesErrorCode,
        stg: STAGES.account,
        errorProperties: {
          mode: MODES.DOMAIN[doDomainsExist]
        }
      })
    }
  }, [doDomainsExist, userCtx.supportedCountriesErrorCode, onError])

  if (!showContent || !pageData) return <></>

  return (
    <>
      {showSupportedCountriesModal && (
        <SupportedCountriesModal
          onClose={() => {
            analyticsUnsupportedCountryErrorList.fireButtonClick(BUTTONS.CLOSE)
            setShowSupportedCountriesModal(false)
            analyticsUnsupportedCountryError.fireScreenDisplayed({
              mode: MODES.DOMAIN[doDomainsExist],
              force: true
            })
          }}
          analyticsScreen={SCREENS.COUNTRY_UNSUPPORTED_ERROR_SUPPORTED_LIST}
        />
      )}
      <TextHeader>{pageData.header}</TextHeader>
      <TextSubHeader>{pageData.subheader}</TextSubHeader>
      <div className={classes.assignOrganization} data-testid="assign-org-page">
        {doDomainsExist && (
          <RadioButtons
            name="radiobutton_default"
            className={classes.radioButtons}
            key="radio"
            onChange={(e, value) => setSelectedOrganization(value)}
          >
            {availableOrganizations.map((org) => {
              return (
                <div
                  data-testid={`radio-button-${org.nodeId}`}
                  key={org.nodeId}
                >
                  <RadioButton
                    checked={org.nodeId === selectedOrganization}
                    className={classes.radioButton}
                    key={org.nodeId}
                    value={org.nodeId}
                    label={org.accountName}
                    disabled={retry}
                  />
                </div>
              )
            })}
            <RadioButton
              checked={selectedOrganization === 'new'}
              className={classes.radioButton}
              key="new_org"
              value="new"
              label={pageData.new_organization}
              disabled={retry}
            />
          </RadioButtons>
        )}
        <div className={doDomainsExist ? classes.new : ''}>
          {(!doDomainsExist || selectedOrganization === 'new') && (
            <>
              <TextBox
                data-testid="organization-entry"
                id="organization"
                value={enteredOrganization}
                onChange={organizationEntryHandler}
                className={classes.textBox}
                label={pageData.organization_name}
                disabled={retry}
                maxLength={1024}
              />
              {enteredOrganization.trim().length > 0 &&
                !isOrganizationNameValid && (
                  <div
                    className={classes.inlineError}
                    data-testid="invalid-org-name-error"
                  >
                    <Suspense fallback={<></>}>
                      <IconMinusCircle
                        filled={true}
                        color="colorRed5"
                        size={20}
                      />
                    </Suspense>
                    <span>{pageData.invalid_name}</span>
                  </div>
                )}
              {isCountrySelectorEnabled &&
                userCtx.supportedCountries?.length > 0 && (
                  <div data-testid="select_field">
                    <Select
                      data-testid="veneer-select"
                      error={isHpPlus && !hpPlusCountrySelected}
                      options={[
                        {
                          value: '',
                          label: pageData.organization_country
                        },
                        ...userCtx.supportedCountries
                      ]}
                      label={pageData.organization_country}
                      visibleOptions={5}
                      value={[selectedCountry]}
                      onChange={(selected) => {
                        countrySelectorHandler(selected)
                      }}
                      className={classes.select}
                      clearIcon={false}
                      disabled={retry}
                    />
                    {isHpPlus && !hpPlusCountrySelected && (
                      <div
                        className={classes.inlineError}
                        data-testid="country-error"
                      >
                        <span>
                          <Suspense fallback={<></>}>
                            <IconWarningAlt filled={true} size={20} />
                          </Suspense>
                        </span>
                        <p
                          onClick={supportedCountriesClickHandler}
                          dangerouslySetInnerHTML={{
                            __html: pageData.country_error
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              <TextArea
                id="organization-description"
                className={classes.textArea}
                label={pageData.organization_description}
                disabled={retry}
                onChange={(e) => {
                  setEnteredOrganizationDescription(e)
                }}
                maxLength={1024}
              />
              <p
                data-testid="assign-org-tou"
                dangerouslySetInnerHTML={{
                  __html: pageData.agree_terms
                }}
              />
            </>
          )}
        </div>
        <div className={classes.buttons}>
          <NextStageButton
            copy={pageData.continue_button_text}
            onClick={assignOwnershipHandler}
            dataTestId="submit"
            disabled={submitDisabled}
            loading={retry}
          />
        </div>
      </div>
    </>
  )
}

export default AssignOrganizationPage
