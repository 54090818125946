import { Stack } from '@jarvis/web-stratus-client'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import { getStackPrefix } from '@/utils/Functions'
export class DeviceAuthGrantApiClient {
  constructor(stack = Stack.pie, authProvider = {}) {
    const defaultBaseURL = () =>
      `https://${getStackPrefix(stack)}us1.api.ws-hp.com/deviceauthgrant/v1`

    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: defaultBaseURL,
      defaultAuthProvider: authProvider
    })
  }

  confirmPairing(uuid, headers) {
    return this.jarvisWebHttpInstance.post({
      url: '/confirm',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        ...headers
      },
      data: `device_uuid=${uuid}`
    })
  }

  _verify(data, headers) {
    return this.jarvisWebHttpInstance.post({
      url: '/verify',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        ...headers
      },
      data: data
    })
  }

  verifyPairing(code, holdDevice, headers) {
    const data = `user_code=${code}&hold_device=${holdDevice}`
    return this._verify(data, headers)
  }
}
