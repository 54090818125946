import React, { useContext, useEffect, useState } from 'react'
import {
  BIZ_MODEL_HP_PLUS,
  DUNE_DESIGN_JET,
  MULTI_FUNCTION_PRINTER,
  OFFER_HINT_SINGLE_SKU,
  SINGLE_FUNCTION_PRINTER,
  SINGLE_SKU_OFFER
} from './Constants'
import { ConfigContext } from '@/store'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const PrinterContext = React.createContext({
  claimPostcard: null,
  cloudId: null,
  productNumber: null,
  fingerprint: null,
  bizModel: null,
  uuid: null,
  serialNumber: null,
  pairingCode: null,
  pollingInterval: null,
  claimTimestamp: null,
  isHpPlus: false,
  printerImage: null,
  name: null,
  modelName: null,
  location: null,
  deviceCapabilities: null,
  hasFaxSupport: null,
  hasScanSupport: null,
  isSingleSku: null,
  offer: null,
  isMono: true,
  deviceSegment: true,
  connectivityFamily: false,
  oid: null,
  isLargeFormat: true,
  setClaimPostcard: EMPTY_FUNCTION,
  setCloudId: EMPTY_FUNCTION,
  setProductNumber: EMPTY_FUNCTION,
  setFingerprint: EMPTY_FUNCTION,
  setBizModel: EMPTY_FUNCTION,
  setUuid: EMPTY_FUNCTION,
  setSerialNumber: EMPTY_FUNCTION,
  setPairingCode: EMPTY_FUNCTION,
  setPollingInterval: EMPTY_FUNCTION,
  setClaimTimestamp: EMPTY_FUNCTION,
  setPrinterImage: EMPTY_FUNCTION,
  setName: EMPTY_FUNCTION,
  setModelName: EMPTY_FUNCTION,
  setLocation: EMPTY_FUNCTION,
  setDeviceCapabilities: EMPTY_FUNCTION,
  setOffer: EMPTY_FUNCTION,
  setIsMono: EMPTY_FUNCTION,
  setDeviceSegment: EMPTY_FUNCTION,
  setConnectivityFamily: EMPTY_FUNCTION,
  setOid: EMPTY_FUNCTION,
  printerFunctionType: SINGLE_FUNCTION_PRINTER
})

const PrinterProvider = (props) => {
  const {
    getStoreData,
    setStoreData,
    sessionContext,
    updateStageContext
  } = useContext(ConfigContext)
  const { onbpReleaseLfpverification: isLfpEnabled } = useFlags()

  const [claimPostcard, setClaimPostcard] = useState(null)
  const [cloudId, setCloudId] = useState(null)
  const [productNumber, setProductNumber] = useState(null)
  const [fingerprint, setFingerprint] = useState(null)
  const [bizModel, setBizModel] = useState(null)
  const [uuid, setUuid] = useState(null)
  const [serialNumber, setSerialNumber] = useState(null)
  const [pairingCode, setPairingCode] = useState(null)
  const [pollingInterval, setPollingInterval] = useState(5)
  const [claimTimestamp, setClaimTimestamp] = useState(null)
  const [printerImage, setPrinterImage] = useState(null)
  const [loadedFromStore, setLoadedFromStore] = useState(false)
  const [name, setName] = useState(null)
  const [modelName, setModelName] = useState(null)
  const [location, setLocation] = useState(null)
  const [deviceCapabilities, setDeviceCapabilities] = useState(null)
  const [hasFaxSupport, setHasFaxSupport] = useState(false)
  const [hasScanSupport, setHasScanSupport] = useState(false)
  const [isSingleSku, setIsSingleSku] = useState(null)
  const [offer, setOffer] = useState(null)
  const [isMono, setIsMono] = useState(null)
  const [deviceSegment, setDeviceSegment] = useState(null)
  const [connectivityFamily, setConnectivityFamily] = useState(null)
  const [oid, setOid] = useState(null)
  const [printerFunctionType, setPrinterFunctionType] = useState(
    SINGLE_FUNCTION_PRINTER
  )

  const resetPrinterData = () => {
    setBizModel(null)
    setPollingInterval(null)
    setHasFaxSupport(null)
    setHasScanSupport(null)
    setClaimPostcard(null)
    setCloudId(null)
    setProductNumber(null)
    setFingerprint(null)
    setUuid(null)
    setSerialNumber(null)
    setPairingCode(null)
    setClaimTimestamp(null)
    setPrinterImage(null)
    setName(null)
    setModelName(null)
    setLocation(null)
    setDeviceCapabilities(null)
    setIsSingleSku(null)
    setOffer(null)
    setIsMono(null)
    setDeviceSegment(null)
    setConnectivityFamily(null)
    setOid(null)
  }

  useEffect(() => {
    if (!loadedFromStore) {
      const printerData = getStoreData('printer')
      if (printerData) {
        setClaimPostcard(printerData.claimPostcard)
        setCloudId(printerData.cloudId)
        setProductNumber(printerData.productNumber)
        setFingerprint(printerData.fingerprint)
        setBizModel(printerData.bizModel)
        setUuid(printerData.uuid)
        setSerialNumber(printerData.serialNumber)
        setPairingCode(printerData.pairingCode)
        setPollingInterval(printerData.pollingInterval)
        setClaimTimestamp(printerData.claimTimestamp)
        setPrinterImage(printerData.printerImage)
        setName(printerData.name)
        setModelName(printerData.modelName)
        setLocation(printerData.location)
        setDeviceCapabilities(printerData.deviceCapabilities)
        setHasFaxSupport(printerData.hasFaxSupport)
        setHasScanSupport(printerData.hasScanSupport)
        setIsSingleSku(printerData.isSingleSku)
        setOffer(printerData.offer)
        setIsMono(printerData.isMono)
        setDeviceSegment(printerData.deviceSegment)
        setConnectivityFamily(printerData.connectivityFamily)
        setOid(printerData.oid)
        setPrinterFunctionType(printerData.printerFunctionType)
      }
      setLoadedFromStore(true)
    }
  }, [getStoreData, loadedFromStore])

  useEffect(() => {
    const capabilities =
      deviceCapabilities || sessionContext?.device?.pls?.deviceCapabilities
    const _isSingleSku =
      sessionContext?.onboardingContext?.offerHint === OFFER_HINT_SINGLE_SKU ||
      sessionContext?.device?.offer === SINGLE_SKU_OFFER

    setIsSingleSku(_isSingleSku)

    if (capabilities) {
      capabilities.forEach((capability) => {
        if (capability.name === 'fax') {
          setHasFaxSupport(capability.support === 'true')
        }
        if (capability.name === 'scan') {
          setHasScanSupport(capability.support === 'true')
        }
      })
    }
  }, [
    deviceCapabilities,
    sessionContext?.device?.pls?.deviceCapabilities,
    sessionContext?.onboardingContext?.offerHint
  ])

  useEffect(() => {
    setPrinterFunctionType(
      hasFaxSupport && hasScanSupport
        ? MULTI_FUNCTION_PRINTER
        : SINGLE_FUNCTION_PRINTER
    )
  }, [hasFaxSupport, hasScanSupport])

  useEffect(() => {
    if (loadedFromStore) {
      setStoreData('printer', {
        claimPostcard,
        cloudId,
        productNumber,
        fingerprint,
        bizModel,
        uuid,
        serialNumber,
        pairingCode,
        pollingInterval,
        claimTimestamp,
        printerImage,
        name,
        modelName,
        location,
        deviceCapabilities,
        hasScanSupport,
        hasFaxSupport,
        isSingleSku,
        offer,
        isMono,
        deviceSegment,
        connectivityFamily,
        oid,
        printerFunctionType
      })
      updateStageContext({
        cloudId,
        bizModel,
        printerImage,
        name,
        modelName,
        location
      })
    }
  }, [
    claimPostcard,
    cloudId,
    productNumber,
    fingerprint,
    bizModel,
    uuid,
    serialNumber,
    pairingCode,
    pollingInterval,
    claimTimestamp,
    printerImage,
    loadedFromStore,
    name,
    modelName,
    location,
    setStoreData,
    deviceCapabilities,
    hasFaxSupport,
    hasScanSupport,
    isSingleSku,
    offer,
    isMono,
    deviceSegment,
    connectivityFamily,
    oid,
    updateStageContext,
    printerFunctionType
  ])

  const checkIsLargeFormat = () => {
    if (isLfpEnabled) {
      return (deviceSegment || '').toLowerCase() === 'lfp'
    } else {
      return (
        !!connectivityFamily &&
        connectivityFamily.filter(
          (x) => x.toLowerCase() === DUNE_DESIGN_JET.toLowerCase()
        ).length > 0
      )
    }
  }

  const printerState = {
    claimPostcard,
    cloudId,
    productNumber,
    fingerprint,
    bizModel,
    uuid,
    serialNumber,
    pairingCode,
    pollingInterval,
    claimTimestamp,
    printerImage,
    hasFaxSupport,
    hasScanSupport,
    deviceCapabilities,
    isSingleSku,
    offer,
    isMono,
    deviceSegment,
    connectivityFamily,
    oid,
    isLargeFormat: checkIsLargeFormat(),
    isHpPlus: bizModel?.toUpperCase() === BIZ_MODEL_HP_PLUS,
    name,
    modelName,
    location,
    setClaimPostcard,
    setCloudId,
    setProductNumber,
    setFingerprint,
    setBizModel,
    setUuid,
    setSerialNumber,
    setPairingCode,
    setDeviceCapabilities,
    setPollingInterval,
    setClaimTimestamp,
    setPrinterImage,
    setName,
    setModelName,
    setLocation,
    setOffer,
    setIsMono,
    setDeviceSegment,
    setConnectivityFamily,
    setOid,
    resetPrinterData,
    printerFunctionType
  }

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
