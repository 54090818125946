import { AxiosResponse } from 'axios'
import { DeviceApiClient } from '@jarvis/web-stratus-client'

export interface Headers {
  'X-Correlation-ID': string
}

export class DeviceIotClient extends DeviceApiClient {
  /**
   * Retrieves current RTP device status
   * @param cloudId
   * @param headers
   * @returns Axios response object
   */
  getRtpStatus(cloudId: string, headers: Headers): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `/${cloudId}/rtpdevicestatus`,
      headers: {
        ...headers
      }
    })
  }

  /**
   * Retrieves current RTP config
   * @param cloudId
   * @param headers
   * @returns Axios response object
   */
  getRtpConfig(cloudId: string, headers: Headers): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `/${cloudId}/rtpconfig`,
      headers: {
        ...headers
      }
    })
  }

  /**
   * Retrieves device details including images
   * @param modelNo - SKU/ProductNumber of the device
   * @param headers
   * @returns Axios response object
   */
  getProductDetails(modelNo: string, headers: Headers): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `models/${modelNo}`,
      headers: {
        ...headers
      }
    })
  }

  /**
   * Sets the configuration of the device
   * @param deviceId
   * @param state desired system state
   * @param headers
   * @returns Axios response object
   */
  setSystemConfiguration(
    deviceId: string,
    state,
    headers: Headers
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.post({
      url: `${deviceId}/system/configuration`,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      data: { state: state }
    })
  }

  /**
   * Retrieves device system identity
   * @param deviceId
   * @param headers
   * @returns Axios response object
   */
  getSystemIdentity(
    deviceId: string,
    headers: Headers
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `${deviceId}/identities`,
      headers: {
        ...headers
      }
    })
  }
}
