import { JarvisWebHttpClient } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client'
import { getStackPrefix } from '@/utils/Functions'

export class TenancyRegistryClient {
  constructor(stack = Stack.pie, authProvider = {}) {
    const defaultBaseURL = () => {
      return `https://${getStackPrefix(
        stack
      )}us1.api.ws-hp.com/tenancy-registry/v1`
    }

    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: defaultBaseURL,
      defaultAuthProvider: authProvider
    })
  }
  /**
   * Get the list of domains for a resource
   */
  getDomains() {
    return this.jarvisWebHttpInstance.get({
      url: `/person/domains`,
      headers: {
        'Content-Type': 'application/json'
      },
      params: { filter: 'admin' }
    })
  }

  /**
   * Get the node details related to a specific nodeId
   */
  getNode(nodeId) {
    return this.jarvisWebHttpInstance.get({
      url: `/node/${nodeId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
