import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LaunchDarkly from '@/store/LaunchDarkly'
import {
  ConfigProvider,
  PrinterProvider,
  UserProvider,
  ErrorProvider,
  SplunkRumProvider,
  DeviceHttpProxyProvider
} from '@/store'
import AppWrapper from './AppWrapper'

const PortalOobe = ({ monitoring, ldClientId, ...props }) => {
  return (
    <Router>
      <ConfigProvider {...props}>
        <SplunkRumProvider monitoring={monitoring}>
          <LaunchDarkly clientId={ldClientId}>
            <UserProvider>
              <PrinterProvider>
                <ErrorProvider>
                  <DeviceHttpProxyProvider>
                    <AppWrapper />
                  </DeviceHttpProxyProvider>
                </ErrorProvider>
              </PrinterProvider>
            </UserProvider>
          </LaunchDarkly>
        </SplunkRumProvider>
      </ConfigProvider>
    </Router>
  )
}

export default PortalOobe
