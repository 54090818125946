import React, { useContext } from 'react'
import { ConfigContext, ErrorContext, UserContext } from '@/store'

const PartnerLinkError = ({ children }) => {
  const { nextStage } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)
  const { resetError } = useContext(ErrorContext)

  const primaryClick = () => {
    resetError()
  }

  const secondaryClick = () => {
    nextStage({
      result: 'failure',
      xCorrelationId: getSessionId()
    })
  }

  return <>{children({ primaryClick, secondaryClick })}</>
}

export default PartnerLinkError
