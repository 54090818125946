import { UiMgtSvcClient } from '@jarvis/web-stratus-client'
import { UCDE_PROGRAM_ID, YETI_PROGRAM_ID } from '@/store/Constants'

export class UiMgtClient extends UiMgtSvcClient {
  /**
   * Get the list of program locales for a resource
   * @param programIdList - array of program IDs
   * @param headers - custom headers object
   * @returns Axios response object
   */
  getProgramLocales(
    programIdList = [UCDE_PROGRAM_ID, YETI_PROGRAM_ID],
    headers = {}
  ) {
    return this.jarvisWebHttpInstance.get({
      url: `/programlocales?programIdList=${programIdList.join(',')}`,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      //Axios need a empty data to keep the header Content-Type https://github.com/axios/axios/issues/86
      data: null
    })
  }
}
