import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AssignOrganizationPage, CreateAccountPage } from '../../index'
import { UserContext, ConfigContext, ErrorContext, STAGES } from '@/store'
import useCreateAccount from '@/hooks/useCreateAccount'
import useTenancyRegistry from '@/hooks/useTenancyRegistry'
import { ACCOUNT_TYPE_COMPANY, ANALYTICS } from '@/store/Constants'

const { MODES } = ANALYTICS

const AccountCreationPage = ({ isUserOnboarding = false }) => {
  const { onError } = useContext(ErrorContext)
  const { navigation, nextStage, isEcpExperience, setIsLoading } = useContext(
    ConfigContext
  )
  const {
    accountType,
    getSessionId,
    isCompanyAccount,
    isPersonalAccount,
    setAccountType,
    organization
  } = useContext(UserContext)
  const {
    createAccount,
    inProgress,
    success,
    error,
    resetState
  } = useCreateAccount()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const orgId = urlSearchParams.get('orgId')
  const {
    isFetching,
    availableOrganizations,
    getOrganizations
  } = useTenancyRegistry()
  const [doDomainsExist, setDoDomainsExist] = useState(false)
  const [didDomainLoad, setDidDomainLoad] = useState(false)
  const [retryBehavior, setRetryBehavior] = useState()
  const isPersonalRequest = isPersonalAccount && !isUserOnboarding
  const isCompanyRequest =
    (isUserOnboarding && !orgId) || (!isUserOnboarding && isCompanyAccount)

  useEffect(() => {
    if (success) {
      if (isUserOnboarding) {
        navigation.push('/')
      } else {
        const tenant = {
          type: accountType
        }
        if (organization) {
          tenant['accountName'] = organization
        }
        nextStage({
          result: 'success',
          output: {
            tenant: tenant
          },
          xCorrelationId: getSessionId()
        })
      }
    } else if (success !== null && error) {
      onError({
        err: error,
        stg: STAGES.account,
        errorProperties: {
          mode: MODES.DOMAIN[doDomainsExist]
        },
        behavior: retryBehavior
      })
      resetState()
    }
  }, [
    success,
    nextStage,
    navigation,
    onError,
    error,
    accountType,
    organization,
    isUserOnboarding,
    getSessionId,
    resetState,
    doDomainsExist,
    retryBehavior
  ])

  useEffect(() => {
    if (isUserOnboarding) {
      setAccountType(ACCOUNT_TYPE_COMPANY)
    }
  }, [isUserOnboarding])

  useEffect(() => {
    if (!isFetching) {
      if (availableOrganizations == null) {
        if (accountType) {
          getOrganizations(accountType)
          return
        }
        getOrganizations()
        return
      }
      setDidDomainLoad(true)
      setDoDomainsExist(
        availableOrganizations && availableOrganizations.length > 0
      )
    }
  }, [
    isFetching,
    availableOrganizations,
    getOrganizations,
    isPersonalRequest,
    isCompanyRequest
  ])

  useEffect(() => {
    if (availableOrganizations && !availableOrganizations.length) {
      document.cookie = 'showTutorial=true; path=/'
    }
  }, [availableOrganizations])

  useEffect(() => {
    if (isUserOnboarding && orgId && !inProgress && !success && !error) {
      setIsLoading(true)
      createAccount({ orgId }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [
    isUserOnboarding,
    orgId,
    inProgress,
    success,
    error,
    orgId,
    createAccount
  ])

  return (
    <div data-testid="account-creation-page">
      {isPersonalRequest && (
        <CreateAccountPage
          createAccount={createAccount}
          inProgress={inProgress}
          availableOrganizations={availableOrganizations}
        />
      )}
      {(isCompanyRequest || isEcpExperience) && (
        <AssignOrganizationPage
          createAccount={createAccount}
          inProgress={inProgress}
          onError={onError}
          setRetryBehavior={setRetryBehavior}
          doDomainsExist={doDomainsExist}
          availableOrganizations={availableOrganizations}
          didDomainLoad={didDomainLoad}
        />
      )}
    </div>
  )
}

export default AccountCreationPage
