// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../pages/Web/FinishSetup/FinishSetupPage.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1vBodJWK02zQKiKGBvVhZ9{flex-direction:row}._1vBodJWK02zQKiKGBvVhZ9 ._3_5UD1P1WDhf5rMwoQEgfK{display:flex;justify-content:center;align-items:center;align-self:center;margin-left:auto;padding-left:45px}@media screen and (max-width: 767px){._1vBodJWK02zQKiKGBvVhZ9 svg[role='img']{display:none}._1vBodJWK02zQKiKGBvVhZ9 ._3_5UD1P1WDhf5rMwoQEgfK{padding-left:19px}}\n", "",{"version":3,"sources":["webpack://./src/components/FinishSetup/SetupOption.module.scss"],"names":[],"mappings":"AAAA,yBAAiG,kBAAkB,CAAC,kDAA+B,YAAY,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,qCAAqC,yCAA8B,YAAY,CAAC,kDAA+B,iBAAiB,CAAC","sourcesContent":[".setupSection{composes:setupSection from 'src/pages/Web/FinishSetup/FinishSetupPage.module.scss';flex-direction:row}.setupSection .buttonContainer{display:flex;justify-content:center;align-items:center;align-self:center;margin-left:auto;padding-left:45px}@media screen and (max-width: 767px){.setupSection svg[role='img']{display:none}.setupSection .buttonContainer{padding-left:19px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setupSection": "_1vBodJWK02zQKiKGBvVhZ9 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["setupSection"] + "",
	"buttonContainer": "_3_5UD1P1WDhf5rMwoQEgfK"
};
export default ___CSS_LOADER_EXPORT___;
