import { ShortcutsTenantMgrSvcClient } from '@jarvis/web-stratus-client'

export class ShortcutsTenantManagerClient extends ShortcutsTenantMgrSvcClient {
  /**
   * Create Shortcut
   */
  createShortcut(deviceUuid) {
    return this.jarvisWebHttpInstance.post({
      url: '/shortcuts?origin=oobe',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        deviceUuid: deviceUuid,
        shortcut: {
          category: ['email'],
          smartTask: {
            jobName: 'Scan to Admin',
            fileType: 'pdf',
            smartTaskConfig: {
              email: {
                tos: [],
                subject: 'You have new files',
                message:
                  'Please check the attached files that have been sent to you.'
              }
            }
          }
        }
      }
    })
  }
}
