import { useCallback, useContext, useMemo } from 'react'
import { DeviceHttpProxyContext } from '@/store/DeviceHttpProxyContext'
import {
  getLedmURIFromManifest,
  getCdmHrefByServiceRelAndMethod,
  getLedmResourceUriFromDiscoveryTree
} from '@/utils/DeviceHttpProxy'
import { DHP_REQUEST_STATUS } from '@/store/Constants'

export function useDeviceHttpProxy() {
  const {
    printerServices,
    fetch,
    isCdm,
    isDhpInitialized,
    getManifest
  } = useContext(DeviceHttpProxyContext)

  /** @typedef {
   * (
   *   dhpService: object,
   *   cdmBody?: object,
   *   ledmBodyParser?: (xmlTemplate: string) => string
   * ) => ReturnType<typeof fetch>} sendRequest */
  const sendRequest = useCallback(
    async (dhpService, cdmBody = undefined, ledmBodyParser = undefined) => {
      let path, bodyData
      let method = dhpService.method

      if (isCdm) {
        const { serviceGun, rel } = dhpService.cdm
        method = dhpService.cdm.method || method
        path = getCdmHrefByServiceRelAndMethod({
          servicesDiscovery: printerServices.data,
          serviceGun,
          rel,
          method
        })
        bodyData = cdmBody && JSON.stringify(cdmBody)
      } else {
        const { resourceType, manifest } = dhpService.ledm
        method = dhpService.ledm.method || method

        if (manifest) {
          const { uriResourceTypeKey, nodes } = manifest
          path = getLedmURIFromManifest({
            manifest: await getManifest(manifest.resourceType),
            uriResourceTypeKey,
            nodes,
            method
          })
        } else {
          path = getLedmResourceUriFromDiscoveryTree(
            printerServices.data,
            resourceType
          )
        }
        bodyData =
          typeof ledmBodyParser === 'function'
            ? ledmBodyParser(dhpService.ledm.bodyTemplate)
            : dhpService.ledm.bodyTemplate
      }

      return fetch({
        path,
        method,
        ...(bodyData && {
          body: { data: bodyData }
        })
      })
    },
    [isCdm, fetch, printerServices, getManifest]
  )

  const readyToSendRequest = useMemo(
    () =>
      isDhpInitialized &&
      printerServices.status === DHP_REQUEST_STATUS.SUCCESS &&
      isCdm !== undefined,
    [isDhpInitialized, printerServices, isCdm]
  )

  return useMemo(
    () => ({
      sendRequest,
      printerServices,
      isDhpInitialized,
      readyToSendRequest,
      isCdm
    }),
    [printerServices, sendRequest, isDhpInitialized, readyToSendRequest, isCdm]
  )
}
